@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c12 {
  background-color: #ffffff;
  color: #333333;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: 'Arial';
  font-style: normal;
}
.c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c5 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c1 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify;
  height: 11pt;
}
.c6 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c8 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 18pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c0 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 24pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c15 {
  color: #ff0000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: 'Calibri';
  font-style: italic;
}
.c4 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: 'Calibri';
  font-style: italic;
}
.c7 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c16 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c10 {
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.c18 {
  color: #ff0000;
  text-decoration: none;
  vertical-align: baseline;
  font-style: italic;
}
.c11 {
  background-color: #ffffff;
  padding: 72pt 72pt 72pt 72pt;
}
.c3 {
  font-size: 12pt;
  font-weight: 400;
  font-family: 'Calibri';
}
.c14 {
  color: inherit;
  text-decoration: inherit;
}
.c13 {
  background-color: #ffffff;
}
.c17 {
  text-indent: 36pt;
}
.c9 {
  margin-left: 36pt;
}
.c19 {
  height: 11pt;
}
.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: 'Arial';
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: 'Arial';
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: 'Arial';
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: 'Arial';
}
h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: 'Arial';
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: 'Arial';
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
